.view-default-img {
    width: 80px;
    // border-radius: 50%;
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    // border: solid 1px #e8e8e8;
    cursor: pointer;

    .icon-add-photo {
        color: #bfbfbf;
        font-size: 20px;
    }

    .icon-text {
        margin-top: 4px;
        color: #595959;
        font-size: 12px;
        line-height: 20px;
    }
}

.square {
    border-radius: 8px;
}

.circle {
    border-radius: 5px;

    // img {
    //     border-radius: 5px;
    // }
}

.edit-image {
    position: relative;
    width: 100%;
    height: 100%;

    .icon {
        position: absolute;
        bottom: 0;
        right: 0;
    }

    .icon-company {
        right: -5px;
        bottom: -5px
    }
}